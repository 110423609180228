import {
  Box,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useBoolean,
} from "@chakra-ui/react";
import { IconBase, message } from "components/base";
import ConfirmModal from "components/modal/ConfirmDeleteModal";
import { CACHED_PROJECT_INFO_KEY } from "constants/cache";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSyncDataOption } from "redux/appSlice";
import { setCachingProject } from "redux/projectSlice";
import { RootState } from "redux/store";
import { sleep } from "utils/common";

const ClearDatabaseCachedIcon = () => {
  const [isClearing, setIsClearing] = useBoolean();
  const [isOpenConfirmClearCache, setIsOpenConfirmClearCache] = useBoolean();

  const { isOnline } = useSelector((state: RootState) => state.app);

  const dispatch = useDispatch();

  const { cachingProjectBimFileId } = useSelector(
    (state: RootState) => state.project
  );

  useEffect(() => {
    if (!isOnline && isOpenConfirmClearCache) {
      setIsOpenConfirmClearCache.off();
    }
  }, [isOnline]);

  const isNotAllowClearCache = useMemo(() => {
    return !isOnline || isClearing || !!cachingProjectBimFileId;
  }, [cachingProjectBimFileId, isClearing, isOnline]);

  const icon = useMemo(
    () => (
      <svg
        style={{
          width: "1.8rem",
          stroke: isOnline ? "rgba(0,0,0,1)" : "rgba(0,0,0,0.5)",
        }}
        viewBox="0 0 20 22"
        fill="none"
      >
        <path
          d="M19 4C19 5.65685 14.9706 7 10 7C5.02944 7 1 5.65685 1 4M19 4C19 2.34315 14.9706 1 10 1C5.02944 1 1 2.34315 1 4M19 4V18C19 19.66 15 21 10 21C5 21 1 19.66 1 18V4M19 11C19 12.66 15 14 10 14C5 14 1 12.66 1 11"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    [isOnline]
  );

  const handleClearCache = useCallback(() => {
    (async () => {
      try {
        dispatch(setCachingProject("clear"));
        dispatch(
          setSyncDataOption({
            mapSyncDataTimeByProject: {},
            mapModelCached: {},
          })
        );
        setIsClearing.on();
        // clear local storage
        localStorage.removeItem(CACHED_PROJECT_INFO_KEY);
        // clear all data indexedDb
        const databases: any[] = await window.indexedDB.databases();
        for (let i = 0; i < databases.length; i++) {
          window.indexedDB.deleteDatabase(databases[i].name);
        }
        const cacheKeys = await caches.keys();
        await Promise.all(
          cacheKeys.map(async (cacheKey) => {
            const cache = await caches.open(cacheKey);
            const keys = await cache.keys();
            await Promise.all(keys.map((item) => cache.delete(item)));
          })
        );
        const registrations = await navigator.serviceWorker.getRegistrations();
        if (registrations.length) {
          await new Promise((resolve, reject) => {
            navigator.serviceWorker.ready
              .then(async (registration) => {
                // send a message command to the service-worker to self-destroy, and the service-worker in turn reloads all controlled clients.
                registration?.active?.postMessage("unregister");
                await sleep(1000);
                // with old service worker not listen message unregister -> must unregister
                resolve(true);
              })
              .catch((error) => {
                reject(error);
              });
          });
        }

        window.location.reload();
        setIsClearing.off();
        setIsOpenConfirmClearCache.off();
        dispatch(setCachingProject(undefined));
      } catch {
        message.error("Clear cache failed.");

        setIsClearing.off();
        setIsOpenConfirmClearCache.off();
        dispatch(setCachingProject(undefined));
      }
    })();
  }, [setIsClearing, setIsOpenConfirmClearCache, dispatch]);

  return (
    <>
      <Flex
        alignItems="center"
        justifyContent="center"
        padding="0.9rem"
        width="4rem"
        height="4rem"
        border="1px solid #A3A3A3"
        borderRadius="6px"
        backgroundColor="#fff"
        opacity={isNotAllowClearCache ? 0.5 : 1}
        cursor={isNotAllowClearCache ? "not-allowed" : "pointer"}
        onClick={
          isNotAllowClearCache
            ? setIsOpenConfirmClearCache.off
            : setIsOpenConfirmClearCache.on
        }
      >
        {icon}
      </Flex>

      {isOpenConfirmClearCache && (
        <ConfirmModal
          title=""
          size="none"
          isShowCloseIcon={false}
          modalContentProps={{ maxWidth: "65rem" }}
          content={
            <Flex gap="0.8rem">
              <IconBase
                icon="/img/database-clear.svg"
                width="2.4rem"
                height="2.4rem"
              />
              <Box color="font.gray" fontSize="1.2rem">
                <Text
                  fontSize="1.8rem"
                  lineHeight="2.4rem"
                  mb="0.6rem"
                  fontWeight={700}
                >
                  ブラウザのキャッシュをクリアする
                </Text>
                <Text mb="1.2rem" lineHeight="1.8rem" fontWeight={700}>
                  （システムの調子がおかしいな、と思ったら、試してみてください）
                </Text>

                <Text lineHeight="2.16rem" mb="0.8rem">
                  下記が全て一度削除されることにより、動作が軽くなったり、不具合が解消される可能性があります。
                </Text>

                <UnorderedList
                  lineHeight="2.16rem"
                  sx={{
                    "li::marker": {
                      color: "#737373",
                    },
                  }}
                >
                  <ListItem>
                    オフラインモードを利用するために、iPadにダウンロードしたプロジェクトデータ
                  </ListItem>
                  <ListItem>
                    <Text> オフラインで作業してiPad内に保持しているデータ</Text>
                    (
                    <Text display="inline" color="font.danger">
                      消したくないオフライン作業済みのデータがある場合、アップロードしてから、実行してください。
                    </Text>
                    ）
                  </ListItem>
                  <ListItem>
                    2回目以降の読込み速度を早くするために、ブラウザ内に保存しておいたjsなどの情報
                  </ListItem>
                </UnorderedList>
              </Box>
            </Flex>
          }
          isLoading={isClearing}
          buttonCancel="キャンセル"
          buttonConfirm="キャッシュをクリア"
          isOpen={isOpenConfirmClearCache}
          onClose={setIsOpenConfirmClearCache.off}
          onProcessing={handleClearCache}
        />
      )}
    </>
  );
};

export default memo(ClearDatabaseCachedIcon);

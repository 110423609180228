import { ApiResponse } from "interfaces/models/api";
import {
  DashboardFilter,
  CustomFilterType,
} from "interfaces/models/customFilter";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";

const path = "/v2/filters";

export const getDashboardFilterList = async (
  filterType: CustomFilterType
): Promise<ApiResponse<DashboardFilter[]>> => {
  return axiosECS.get(`${path}/dashboard`, {
    params: { filterType },
  });
};

export const createDashboardFilter = async (
  filter: DashboardFilter
): Promise<ApiResponse<DashboardFilter>> => {
  return axiosECS.post(`${path}/dashboard`, validateBodyReq(filter));
};

export const updateDashboardFilter = async (
  filter: DashboardFilter
): Promise<ApiResponse<DashboardFilter>> => {
  return axiosECS.post(`${path}/dashboard`, validateBodyReq(filter));
};

export const deleteDashboardFilterList = async (
  ids: string[]
): Promise<ApiResponse<any[]>> => {
  return axiosECS.delete(`${path}/dashboard`, {
    data: { ids: validateListString(ids) },
  });
};

import { ApiResponse } from "interfaces/models/api";
import { ProjectDetail } from "interfaces/models/project";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";

const path = "/v2/projects";

export const getProjectList = async (): Promise<
    ApiResponse<ProjectDetail[]>
> => {
    return axiosECS.get(path);
};

export const getProject = async (
    projectId: string
): Promise<ApiResponse<ProjectDetail>> => {
    return axiosECS.get(`${path}/${projectId}`);
};

export const deleteProjectList = async (
    ids: string[]
): Promise<ApiResponse<string[]>> => {
    return axiosECS.delete(path, { data: { ids } });
};

export const createProject = async (
    project: ProjectDetail
): Promise<ApiResponse<ProjectDetail>> => {
    return axiosECS.post(path, validateBodyReq(project));
};

export const updateProject = async (
    project: ProjectDetail
): Promise<ApiResponse<ProjectDetail>> => {
    return axiosECS.patch(path, validateBodyReq(project));
};
import { ApiResponse } from "interfaces/models/api";
import { ForgeProject } from "interfaces/models/project";
import { axiosECS } from "services/baseAxios";

const pathV1 = "/v1/forge/projects";

interface ForgeReq {
  projectId: string;
  forceGet?: boolean;
}
interface PropertiesByVersionIdReq {
  projectId: string;
  versionId: string;
  data?: any;
}

export const getProjects = async (
  forceGet?: boolean
): Promise<ApiResponse<ForgeProject[]>> => {
  return axiosECS.get(pathV1, { params: { forceGet: forceGet || false } });
};

export const getProjectById = async ({
  projectId,
  forceGet,
}: ForgeReq): Promise<ApiResponse<ForgeProject>> => {
  return axiosECS.get(`${pathV1}/${projectId}`, {
    params: { forceGet: forceGet || false },
  });
};

export const getFolderById = async ({
  projectId,
  folderId,
  forceGet,
}: ForgeReq & { folderId: string }): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(`${pathV1}/${projectId}/folders/${folderId}`, {
    params: { forceGet: forceGet || false },
  });
};

export const getTopFolder = async ({
  projectId,
  forceGet,
}: ForgeReq): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(`${pathV1}/${projectId}/folders/top-folder`, {
    params: { forceGet: forceGet || false },
  });
};

export const getFirstBim = async ({
  projectId,
}: ForgeReq): Promise<ApiResponse<any>> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/first`);
};

export const getDerivativesByVersionId = async ({
  projectId,
  versionId,
}: ForgeReq & { versionId: string }): Promise<ApiResponse<any>> => {
  return axiosECS.get(
    `${pathV1}/${projectId}/bims/version/${versionId}/derivatives`
  );
};

export const getVersionsByBimFileId = async ({
  projectId,
  bimFileId,
  shouldCache,
}: ForgeReq & { bimFileId: string; shouldCache?: boolean }): Promise<
  ApiResponse<any[]>
> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/${bimFileId}/versions`, {
    params: {
      shouldCache,
    },
  });
};
export const getExternalIdsByVersionId = async ({
  projectId,
  versionId,
  shouldCache,
}: ForgeReq & { versionId: string; shouldCache?: boolean }): Promise<
  ApiResponse<any>
> => {
  return axiosECS.get(
    `${pathV1}/${projectId}/bims/version/${versionId}/external-ids`,
    {
      params: {
        shouldCache,
      },
    }
  );
};

export const getAECByVersionId = async ({
  projectId,
  versionId,
  shouldCache,
}: ForgeReq & { versionId: string; shouldCache?: boolean }): Promise<
  ApiResponse<any>
> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/version/${versionId}/aec`, {
    params: { shouldCache },
  });
};

// API NOT USE ANY MORE
export const getBimList = async ({
  projectId,
  searchValue,
  isAllVersion,
}: ForgeReq & { searchValue?: string; isAllVersion?: boolean }): Promise<
  ApiResponse<any[]>
> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims`, {
    params: { searchValue, isAllVersion },
  });
};

export const getPropertiesByVersionId = async ({
  projectId,
  versionId,
  data,
}: PropertiesByVersionIdReq): Promise<ApiResponse<any>> => {
  return axiosECS.post(
    `${pathV1}/${projectId}/bims/versions/${versionId}/properties`,
    { ...data }
  );
};

export const getThumbnailByBimFileId = async ({
  projectId,
  bimFileId,
}: ForgeReq & { bimFileId: string }): Promise<ApiResponse<string>> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/${bimFileId}/thumbnail`);
};

export const getDerivativesByBimFileId = async ({
  projectId,
  bimFileId,
}: ForgeReq & { bimFileId: string }): Promise<ApiResponse<any>> => {
  return axiosECS.get(`${pathV1}/${projectId}/bims/${bimFileId}/derivatives`);
};

export const upLoadBimFile = async ({
  projectId,
  formData,
}: ForgeReq & { formData: FormData }): Promise<ApiResponse<any>> => {
  return axiosECS.post(`${pathV1}/${projectId}/bims`, formData);
};

export const getFolders = async ({
  projectId,
}: ForgeReq): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(`${pathV1}/${projectId}/folders`);
};

export const getModelDbIds = async (body: {
  bimFileId: string;
  level: string;
  version: string;
  viewMode: string;
  dbIdToIndex: { [key: string]: number };
}): Promise<ApiResponse<{ main: number[]; linked: number }>> => {
  const { bimFileId, version, level, dbIdToIndex, viewMode } = body;
  const versionId = encodeURIComponent(`${bimFileId}?version=${version}`);

  return axiosECS.post(`${pathV1}/null/bims/model-db-ids`, {
    versionId,
    level,
    dbIdToIndex,
    viewMode,
  });
};

import { ApiResponse } from "interfaces/models/api";
import { axiosECS } from "services/baseAxios";

const path = "/v2/bim-files";

export const getBimFileList = async (): Promise<ApiResponse<any[]>> => {
  return axiosECS.get(path);
};

export const getLastUpdatedResource = async (body: {
  bimFileId: string;
  localTime: string;
}): Promise<ApiResponse<{ lastUpdated: string; resource: string }[]>> => {
  return axiosECS.post(`${path}/last-updated-resources`, body);
};

export const updateBimFile = async (body: {
  id: string;
  isGenerated: boolean;
}) => {
  return axiosECS.patch(path, body);
};
import { ApiResponse } from "interfaces/models/api";
import { DocumentItemLog } from "interfaces/models/documentItem";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";

const path = "/v2/document-item-logs";

interface GetItemLogReq {
  subItemId?: string;
  itemId?: string;
  categoryId?: string;
  cursor?: string;
  limit?: number;
  shouldCache?: boolean;
}

const getItemLogList = async (
  params: GetItemLogReq
): Promise<ApiResponse<DocumentItemLog[]>> => {
  params.limit = params.limit || 200;

  return axiosECS.get(path, { params });
};

export const createItemLog = async (
  log: DocumentItemLog
): Promise<ApiResponse<DocumentItemLog>> => {
  const body: DocumentItemLog = validateBodyReq(log) as any;
  body.value = {
    data: body.value,
    dynamicFieldKeysUpdatedAt: body?.dynamicFieldKeysUpdatedAt,
  };
  if (!["data", "dynamicField"].includes(log.field)) {
    delete body.value?.dynamicFieldKeysUpdatedAt;
  }

  return axiosECS.post(path, body);
};

export const updateItemLog = async (
  log: DocumentItemLog
): Promise<ApiResponse<DocumentItemLog>> => {
  return axiosECS.patch(path, validateBodyReq(log));
};

export const handleGetItemLogList = async ({
  itemId,
  subItemId,
  categoryId,
  shouldCache,
}: {
  itemId?: string;
  subItemId?: string;
  categoryId?: string;
  shouldCache?: boolean;
}) => {
  const itemLogs: DocumentItemLog[] = [];
  let cursor: string | undefined;
  do {
    const { data, pagination } = await getItemLogList({
      itemId,
      subItemId,
      categoryId,
      cursor,
      shouldCache,
    });
    cursor = pagination?.cursor;
    if (data?.length) {
      itemLogs.push.apply(itemLogs, data);
    }
  } while (cursor);

  return itemLogs;
};

import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { CONTAINER_LAYOUT_ID } from "constants/styleProps";
import React, { memo } from "react";
import Header from "./Header";

interface Props {
  style?: BoxProps;
  children?: React.ReactNode;
  bg?: string;
}

const Layout = ({ children, bg, style }: Props) => {
  return (
    <Box
      id={CONTAINER_LAYOUT_ID}
      w="100%"
      h="100%"
      position={{ base: "absolute", md: "fixed" }}
      {...style}
    >
      <Flex flexDirection="column">
        <Header bg={bg} />
        {children}
      </Flex>
    </Box>
  );
};

export default memo(Layout);

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuItemOptionProps,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { IconBase } from "components/base";
import { PCTooltip } from "components/PCTooltip";
import { SvgIcon } from "components/SvgIcon";
import { WidgetsMode } from "constants/enum";
import { LEVEL_OTHER, LEVEL_OTHER_ID } from "constants/forge";
import useGetCurrentPath from "hooks/useGetCurrentPath";
import useLevel from "hooks/useLevel";
import useTextHover from "hooks/useTextHover";
import { Level } from "interfaces/models";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/store";
import { routePath } from "routes/path";

interface Props {
  currentWidgetActive?: WidgetsMode;
  levelSelectedLabel?: string;
  isMobile?: boolean;
  onOpenProjectDashboard?: () => void;
  onOpenUserDashboard?: () => void;
  onChangeProject?: (id: any) => void;
  onChangeFloor?: (guid: any) => void;
  isLargeScreenForgeViewer?: Boolean;
}

const RenderLevelOptionItem = ({
  isSelected,
  label,
  ...rest
}: MenuItemOptionProps & { label: string; isSelected: boolean }) => {
  const { hoverStatus, textElementRef } = useTextHover();

  return (
    <MenuItemOption
      {...rest}
      className="text-ellipsis"
      fontSize={{
        base: "1.4rem",
        sm: "1.3rem",
        xxl: "1.4rem",
      }}
      fontWeight="medium"
      fontFamily="NotoSansCJKjp"
      p=".5rem 1.5rem"
      borderBottom="1px solid var(--primary-border-color)"
      _last={{ borderBottom: "none" }}
      _hover={{ bg: "#F0F9FF" }}
      _focus={{ bg: "#F0F9FF" }}
      icon={undefined}
      sx={{
        "& > span:first-of-type": {
          display: "none",
        },
        "& > span:nth-of-type(2)": {
          width: "100%",
        },
      }}
    >
      <PCTooltip label={label} isDisabled={!hoverStatus}>
        <Flex alignItems="center" gap="0.5rem" width="100%">
          <Box flexShrink={0} width="1.6rem" height="1.6rem">
            {isSelected && (
              <SvgIcon
                src="/img/icon-action-check_circle.svg"
                width="1.6rem"
                height="1.6rem"
              />
            )}
          </Box>
          <Text ref={textElementRef} className="text-ellipsis">
            {label}
          </Text>
        </Flex>
      </PCTooltip>
    </MenuItemOption>
  );
};

function ForgeHeaderItem({
  levelSelectedLabel,
  isMobile,
  isLargeScreenForgeViewer,
}: Props) {
  const { params, pathPattern } = useGetCurrentPath();
  const navigate = useNavigate();

  const { projectDetail, dataProjectDetail } = useSelector(
    (state: RootState) => state.project
  );
  const { isOnline, syncDataOption } = useSelector(
    (state: RootState) => state.app
  );

  const { isLoadedViewerModelData } = useSelector(
    (state: RootState) => state.forgeViewer
  );

  const isForgePage = useMemo(
    () => pathPattern === routePath.ForgeViewer,
    [pathPattern]
  );

  const {
    levelSelected,
    levels,
    isWaittingCaptureKeynote,
    isGeneratingFamilyInstances,
  } = useSelector((state: RootState) => state.forgeViewer);

  const isDisabledLevelOther = useMemo(() => {
    if (!dataProjectDetail?.id || isOnline) {
      return false;
    }

    const modelCached =
      (syncDataOption?.mapModelCached || {})?.[dataProjectDetail?.id || ""]?.[
        LEVEL_OTHER.label || ""
      ] || {};

    return !modelCached.modelType?.length;
  }, [isOnline, syncDataOption, dataProjectDetail.id]);

  const levelSelectedRef = useRef(levelSelected);
  useEffect(() => {
    levelSelectedRef.current = levelSelected;
  }, [levelSelected]);

  const { handleChangeFloor } = useLevel(projectDetail.id);

  const change = (guid: string | string[]) => {
    if (guid === levelSelectedRef?.current?.guid) {
      return;
    }
    const level = handleChangeFloor(guid);
    levelSelectedRef.current = level!;
  };

  return (
    <>
      <Flex
        alignItems={isMobile ? "flex-start" : "center"}
        flexDir={isMobile ? "column" : "row"}
        gap={isLargeScreenForgeViewer ? "0.8rem" : "3.2rem"}
      >
        <Box>
          <Breadcrumb
            spacing="0.8rem"
            separator={
              <IconBase
                icon="/img/arrow-right4.svg"
                width="24px"
                height="24px"
                color="#262626"
              />
            }
            sx={{
              ol: {
                display: "flex",
                alignItems: "center",
                ...(isMobile && {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  paddingBlockStart: "1.2rem",
                  paddingInlineEnd: "2.4rem",
                }),
              },
            }}
            color="font.default"
          >
            <BreadcrumbItem>
              <BreadcrumbLink
                minWidth="12rem"
                onClick={() => {
                  navigate(routePath.Home);
                }}
              >
                プロジェクト一覧
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <Tooltip label={projectDetail?.name}>
                <BreadcrumbLink
                  {...(!isMobile && {
                    noOfLines: 1,
                    maxWidth: "25rem",
                    wordBreak: "break-all",
                  })}
                  onClick={() => {
                    navigate(`${routePath.Home}#project-${projectDetail.id}`);
                  }}
                >
                  {projectDetail?.name ?? <Spinner />}
                </BreadcrumbLink>
              </Tooltip>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <Tooltip label={dataProjectDetail?.bimFileName}>
                <BreadcrumbLink
                  {...(!isMobile && {
                    noOfLines: 1,
                    maxWidth: "25rem",
                    wordBreak: "break-all",
                  })}
                  _hover={{ textDecoration: "none", cursor: "default" }}
                >
                  {dataProjectDetail?.bimFileName ?? <Spinner />}
                </BreadcrumbLink>
              </Tooltip>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>

        <Menu isOpen={isForgePage ? undefined : false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                className="btn-level-selected"
                as={Button}
                isDisabled={
                  !params?.projectBimFileId ||
                  isGeneratingFamilyInstances ||
                  isWaittingCaptureKeynote ||
                  !isLoadedViewerModelData
                }
                variant="whiteIcon"
                minW="11rem"
                w={{
                  base: "18rem",
                  sm: "14rem",
                  "2xl": "18rem",
                }}
                fontSize={{ base: "1.4rem", sm: "1.3rem", xxl: "1.4rem" }}
                mt={isMobile ? "1rem" : 0}
                mr="1rem !important"
                height="4rem"
                border="1px solid #A3A3A3 !important"
                padding={{
                  base: "0.8rem 1.6rem",
                  lg: "0.8rem 1rem",
                  "2xl": "0.8rem 1.6rem",
                }}
                textAlign={params?.projectBimFileId ? "center" : "left"}
                boxShadow="none"
                sx={{
                  "&:focus": {
                    boxShadow: "none",
                  },
                  "&[disabled]": {
                    opacity: "0.5 !important",
                  },
                  "span:nth-of-type(2)": {
                    base: {
                      flex: "unset",
                      mr: "auto",
                    },
                    sm: {
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    },
                  },
                  ...(!isForgePage
                    ? {
                        backgroundColor: "#FFFFFF61",
                        opacity: 0.4,
                        cursor: "not-allowed",
                      }
                    : {}),
                }}
                leftIcon={
                  !levelSelectedLabel && isForgePage ? (
                    <Spinner size="md" color="blue.500" />
                  ) : params?.projectBimFileId && isForgePage ? (
                    <SvgIcon src="/img/icon-maps-floor.svg" />
                  ) : undefined
                }
                rightIcon={
                  params?.projectBimFileId && levelSelectedLabel ? (
                    <SvgIcon
                      src="/img/arrow-right3.svg"
                      transition="0.4s"
                      transform={isOpen ? "rotate(270deg)" : "rotate(90deg)"}
                    />
                  ) : undefined
                }
              >
                {params?.projectBimFileId ? levelSelectedLabel : "-"}
              </MenuButton>
              <MenuList
                w={{
                  base: "18rem",
                  sm: "14rem",
                  "2xl": "18rem",
                }}
                maxH={{
                  base: "48rem",
                  sm: "34rem",
                  "2xl": "48rem",
                }}
                overflow="auto"
                zIndex="popover"
              >
                <MenuOptionGroup
                  defaultValue={levelSelected.guid || ""}
                  title=""
                  type="radio"
                  value={levelSelected.guid || ""}
                  onChange={handleChangeFloor}
                >
                  {levels
                    ?.filter(
                      (level: Level) =>
                        level.guid &&
                        (level.guid !== LEVEL_OTHER_ID || level.sheets?.length)
                    )
                    .map((d, i) => (
                      <RenderLevelOptionItem
                        key={i}
                        value={d.guid}
                        label={d.label}
                        isDisabled={
                          d.guid === LEVEL_OTHER_ID
                            ? isDisabledLevelOther
                            : false
                        }
                        isSelected={d.guid === levelSelected.guid}
                        onClick={() => {
                          change(d.guid);
                        }}
                      />
                    ))}
                </MenuOptionGroup>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
    </>
  );
}

export default ForgeHeaderItem;

import { ApiResponse } from "interfaces/models/api";
import { Document } from "interfaces/models/document";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";
import { presignedAndDownloadFileS3 } from "utils/file";

interface GetDocumentsReq {
  documentCategoryIds?: string[];
  bimFileId?: string;
  shouldCache?: boolean;
}

const path = "/v2/documents";

const getDocumentList = async (
  params: GetDocumentsReq
): Promise<ApiResponse<Document[]>> => {
  const { bimFileId, documentCategoryIds } = params;

  return axiosECS.get(path, {
    params: {
      bimFileId: encodeURIComponent(bimFileId || ""),
      documentCategoryIds: documentCategoryIds?.join(","),
      shouldCache: params.shouldCache,
    },
  });
};

export const createDocument = async (
  document: Document
): Promise<ApiResponse<Document>> => {
  return axiosECS.post(path, validateBodyReq(document));
};
export const updateDocument = async (
  document: Partial<Document>
): Promise<ApiResponse<Document>> => {
  return axiosECS.patch(path, validateBodyReq(document));
};

export const deleteDocumentList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids: validateListString(ids) } });
};

export const handleGetDocumentList = async (params: GetDocumentsReq) => {
  const { data: docs } = await getDocumentList(params);
  if (params.shouldCache) {
    const requests: Promise<any>[] = [];
    docs.forEach((doc) => {
      if (doc.content) {
        requests.push(
          presignedAndDownloadFileS3(doc.content, params.shouldCache)
        );
      }
    });
    await Promise.all(requests);
  }

  return docs;
};

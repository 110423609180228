import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { SvgIcon } from "components/SvgIcon";
import { usePermission } from "hooks/usePermission";
import { memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { resetWidgetsMode } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";

const HomeHeaderSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isOnline } = useSelector((state: RootState) => state.app);
  const { widgetsMode } = useSelector((state: RootState) => state.forgeViewer);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const {
    canAccessBlackboardTemplate,
    canAccessDocumentTemplate,
    canAccessTaskTypeList,
    canAccessTaskSheetDoc,
    canAccessSettingFamily,
  } = usePermission(currentUser?.role);

  const navigateToDocumentTemplateList = useCallback(() => {
    navigate(generatePath(routePath.DocumentTemplateList));
  }, [navigate]);

  const navigateToTaskTypeList = useCallback(() => {
    navigate(generatePath(routePath.ListTaskType));
  }, [navigate]);

  const navigateToBlackboardTemplateList = useCallback(() => {
    navigate(generatePath(routePath.blackboardTemplateList));
  }, [navigate]);

  const navigateToTaskSheetTemplateTemplateList = useCallback(() => {
    navigate(generatePath(routePath.TaskSheetTemplateList));
  }, [navigate]);

  const navigateToSettingFamily = useCallback(() => {
    navigate(generatePath(routePath.SettingFamily));
  }, [navigate]);

  const options = useMemo(
    () =>
      [
        {
          title: "書類テンプレ一覧",
          onClick: navigateToDocumentTemplateList,
          show: canAccessDocumentTemplate,
        },
        {
          title: "指摘候補一覧",
          onClick: navigateToTaskTypeList,
          show: canAccessTaskTypeList,
        },
        {
          title: "黒板テンプレ一覧",
          onClick: navigateToBlackboardTemplateList,
          show: canAccessBlackboardTemplate,
        },
        {
          title: "是正指示書テンプレ一覧",
          onClick: navigateToTaskSheetTemplateTemplateList,
          show: canAccessTaskSheetDoc,
        },
        {
          title: "検査対象抽出条件一覧",
          onClick: navigateToSettingFamily,
          show: canAccessSettingFamily,
        },
      ].filter((row) => row.show),
    [
      navigateToDocumentTemplateList,
      canAccessDocumentTemplate,
      navigateToTaskTypeList,
      canAccessTaskTypeList,
      navigateToBlackboardTemplateList,
      canAccessBlackboardTemplate,
      navigateToTaskSheetTemplateTemplateList,
      canAccessTaskSheetDoc,
      navigateToSettingFamily,
      canAccessSettingFamily,
    ]
  );

  if (options.length === 0) return null;

  return (
    <Menu autoSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={IconButton}
            aria-label="Options"
            icon={
              <Flex
                alignItems="center"
                justifyContent="center"
                padding="1.1rem"
                width="4rem"
                height="4rem"
                border="1px solid #A3A3A3"
                borderRadius="6px"
                backgroundColor={isOpen ? "#F0F9FF" : "#fff"}
              >
                <SvgIcon
                  src="/img/icon-setting-template.svg"
                  width="2rem"
                  height="2rem"
                  flexShrink="0"
                  sx={{
                    path: {
                      stroke: isOpen
                        ? "var(--primary-color)"
                        : "var(--primary-text-color)",
                    },
                  }}
                />
              </Flex>
            }
            border="none"
            background="white !important"
          />
          <MenuList
            minW="18rem"
            style={{
              padding: "0px",
              margin: "0px",
              background: "#fff",
              border: "1px solid #A3A3A3",
              borderRadius: "6px",
              boxShadow:
                "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={option.title}
                p="0.4rem 1.6rem"
                fontSize="1.6rem"
                lineHeight="2.4rem"
                color="var(--primary-text-color)"
                borderBottom="1px solid var(--primary-border-color)"
                _last={{ borderBottom: "none" }}
                _hover={{ bgColor: "#F0F9FF !important" }}
                onClick={() => {
                  widgetsMode && dispatch(resetWidgetsMode());
                  option.onClick();
                }}
                isDisabled={!isOnline}
              >
                {option.title}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default memo(HomeHeaderSetting);

import { ApiResponse } from "interfaces/models/api";
import { TaskSheetTemplate } from "interfaces/models/taskSheetTemplate";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";

const path = "/v2/task-sheet-templates";

interface GetTemplateReq {
  cursor?: string;
  limit?: number;
  shouldCache?: boolean;
}

export const getTemplateList = async (
  params: GetTemplateReq
): Promise<ApiResponse<TaskSheetTemplate[]>> => {
  return axiosECS.get(path, { params });
};

export const getTemplate = async (
  taskSheetTemplateId: string
): Promise<ApiResponse<TaskSheetTemplate>> => {
  return axiosECS.get(`${path}/${taskSheetTemplateId}`);
};

export const createTemplate = async (
  template: TaskSheetTemplate
): Promise<ApiResponse<TaskSheetTemplate>> => {
  return axiosECS.post(path, validateBodyReq(template));
};

export const updateTemplate = async (
  template: TaskSheetTemplate
): Promise<ApiResponse<TaskSheetTemplate>> => {
  return axiosECS.patch(path, validateBodyReq(template));
};

export const deleteTemplateList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids: validateListString(ids) } });
};

import { Center } from "@chakra-ui/react";
import Spinner from "./ui/Spinner";

export default function Loading() {
  return (
    <Center
      zIndex={9999}
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
    >
      <Spinner />
    </Center>
  );
}

import { ApiResponse } from "interfaces/models/api";
import { BlackboardTemplate } from "interfaces/models/blackboardTemplate";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";

const path = "/v2/blackboard-templates";

export const getTemplateList = async (
  shouldCache = false
): Promise<ApiResponse<BlackboardTemplate[]>> => {
  return axiosECS.get(path, {
    params: {
      shouldCache,
    },
  });
};

export const getTemplate = async (
  blackboardTemplateId: string
): Promise<ApiResponse<BlackboardTemplate>> => {
  return axiosECS.get(`${path}/${blackboardTemplateId}`);
};

export const createTemplate = async (
  template: BlackboardTemplate
): Promise<ApiResponse<BlackboardTemplate>> => {
  return axiosECS.post(path, validateBodyReq(template));
};

export const updateTemplate = async (
  template: BlackboardTemplate
): Promise<ApiResponse<BlackboardTemplate>> => {
  return axiosECS.patch(path, validateBodyReq(template));
};

export const deleteTemplateList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids: validateListString(ids) } });
};

import { DocumentGroupDTO } from "interfaces/dtos/documentGroupDTO";
import { ApiResponse, Pagination } from "interfaces/models/api";
import { DocumentGroup } from "interfaces/models/documentGroup";
import { axiosECS } from "services/baseAxios";

const path = "/v2/document-groups";
const DEFAULT_LIMIT = 200;

interface GetDocumentGroupReq extends Pagination {
  bimFileId?: string;
  projectBimFileId?: string;
  level?: string;
  signal?: AbortSignal;
  shouldCache?: boolean;
}

export const getGroupsList = async (
  params: GetDocumentGroupReq
): Promise<ApiResponse<DocumentGroup[]>> => {
  const { signal, ...rest } = params;

  return axiosECS.get(path, {
    params: { ...rest, limit: rest.limit || DEFAULT_LIMIT },
    signal,
  });
};

export const createGroup = async (
  group: DocumentGroupDTO
): Promise<ApiResponse<DocumentGroup>> => {
  return axiosECS.post(path, group);
};

export const updateGroup = async (
  group: Required<Pick<DocumentGroup, "id">> & Partial<DocumentGroup>
): Promise<ApiResponse<DocumentGroup>> => {
  return axiosECS.patch(path, group);
};

export const deleteGroupList = async (
  ids: string[]
): Promise<ApiResponse<any[]>> => {
  return axiosECS.delete(path, { data: { ids } });
};

import { createStandaloneToast, Flex, Spinner, Text } from "@chakra-ui/react";
import { message } from "components/base";
import ErrorBoundary from "components/ErrorBoundary";
import { CHANGE_NETWORK_STATUS_EVENT } from "constants/serviceWorker";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setActualNetworkStatus,
  setAppSize,
  setIsCheckedPrecacheMissStaticFile,
  setIsSyncOfflineData,
  setTimeBeginOffline,
} from "redux/appSlice";
import store, { RootState } from "redux/store";
import { doRefreshToken } from "utils/authen";
import { syncCachedDataToOnline, syncCachedFilesToOnline } from "utils/cache";
import { getLocalStorage, setLocalStorage } from "utils/storage";
import AppRoute from "./routes";
import { register as registerServiceWorker } from "./serviceWorkerRegistration";

const { toast, ToastContainer } = createStandaloneToast();

export { toast };

function App() {
  const disableSW = process.env.NODE_ENV === "development";

  const {
    isInstallingServiceWorker,
    shouldLogout,
    isCheckedPrecacheMissStaticFile,
    isServiceWorkerReady,
    isOnline,
  } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldLogout === 0) {
      return;
    }
    window.location.href = process.env.REACT_APP_PLANETS_AUTH_LOGIN_URL!;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLogout]);

  useEffect(() => {
    const currentVersion = getLocalStorage("ui-version");
    const metaUi = document.getElementById("ui-version");
    const newVersion = metaUi?.getAttribute("content");
    if (currentVersion !== newVersion) {
      setLocalStorage("ui-version", newVersion || "");
    }
  }, []);

  useEffect(() => {
    if (disableSW) return;
    const handleOnline = async () => {
      if (!("serviceWorker" in navigator)) {
        return;
      }

      const response = await doRefreshToken();
      if (!response) {
        return;
      }

      navigator.serviceWorker.ready.then(function (swRegistration) {
        return swRegistration.active?.postMessage(
          `${CHANGE_NETWORK_STATUS_EVENT}:true`
        );
      });
      dispatch(setIsSyncOfflineData(true));
      setTimeout(async () => {
        await Promise.all([
          syncCachedDataToOnline(),
          syncCachedFilesToOnline(),
        ]);
        dispatch(setIsSyncOfflineData(false));
        dispatch(setTimeBeginOffline(0));
        // after sync data -> register service worker
        registerServiceWorker();
      });
    };

    const handleOffline = () => {
      if (!("serviceWorker" in navigator)) {
        return;
      }

      // clear event update found
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.removeEventListener("updatefound", (e) => {});
        });
      });

      navigator.serviceWorker.ready.then(function (swRegistration) {
        return swRegistration.active?.postMessage(
          `${CHANGE_NETWORK_STATUS_EVENT}:false`
        );
      });
    };
    if (isOnline) {
      handleOnline();
    } else {
      handleOffline();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isOnline]);

  useEffect(() => {
    const handleOnline = () => {
      dispatch(setActualNetworkStatus(true));
    };

    const handleOffline = () => {
      if (store.getState().app.isShowOfflineMsg) {
        message.warning(
          "ネットワークの接続が確認できないため、オフラインモードに移行します。"
        );
      }
      dispatch(setActualNetworkStatus(false));
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [dispatch]);

  useEffect(() => {
    let timeout: any = null;
    const appHeight = () => {
      if (timeout) clearTimeout(timeout);

      return setTimeout(() => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const doc = document.documentElement;
        // Delay for get correct height screen
        doc.style.setProperty("--app-height", `${windowHeight}px`);
        store.dispatch(
          setAppSize({ width: windowWidth, height: windowHeight })
        );
      }, 300);
    };
    timeout = appHeight();
    window.addEventListener("resize", appHeight);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener("resize", appHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!("serviceWorker" in navigator)) {
      dispatch(setIsCheckedPrecacheMissStaticFile(true));
    }

    if (isServiceWorkerReady && !isCheckedPrecacheMissStaticFile) {
      dispatch(setIsCheckedPrecacheMissStaticFile(true));

      setTimeout(() => {
        window.location.reload();
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isServiceWorkerReady, isCheckedPrecacheMissStaticFile]);

  return (
    <ErrorBoundary>
      {!disableSW &&
        (isInstallingServiceWorker || !isCheckedPrecacheMissStaticFile) && (
          <Flex
            id="asdfasdfasdf"
            position="absolute"
            left={0}
            top={0}
            width="100vw"
            height="100vh"
            backgroundColor="rgba(0,0,0,0.2)"
            zIndex={100}
            cursor="progress"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap="10px"
          >
            <Spinner size="xl" />
            <Text fontWeight="bold" fontSize="3rem">
              Installing app...
            </Text>
          </Flex>
        )}
      <AppRoute />
      <ToastContainer />
    </ErrorBoundary>
  );
}

export default App;

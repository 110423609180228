import { ApiResponse } from "interfaces/models/api";
import { TaskComment } from "interfaces/models/task";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";
import { getPreSignUrl, presignedAndDownloadFileS3 } from "utils/file";

interface GetTaskCommentsReq {
  taskId: string;
  cursor?: string;
  limit?: number;
}
const pathV1 = "/v2/task-comments";

const getCommentList = async (
  params: GetTaskCommentsReq,
  shouldCache = false
): Promise<ApiResponse<TaskComment[]>> => {
  return axiosECS.get(pathV1, {
    params: { ...params, limit: params.limit || 10, shouldCache },
  });
};

export const createComment = async (
  comment: Partial<TaskComment>
): Promise<ApiResponse<TaskComment>> => {
  return axiosECS.post(pathV1, validateBodyReq(comment));
};

export const updateComment = async (
  comment: TaskComment
): Promise<ApiResponse<TaskComment>> => {
  return axiosECS.patch(pathV1, validateBodyReq(comment));
};

export const deleteCommentList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(pathV1, { data: { ids } });
};

export const handleGetTaskComments = async (
  body: GetTaskCommentsReq,
  shouldCache = false
) => {
  if (!body.taskId) return;

  const res = await getCommentList(body, shouldCache);

  const requests: Promise<any>[] = [];
  if (res?.data) {
    res.data.forEach((comment: TaskComment) =>
      comment.images?.forEach((image) => {
        requests.push(presignedAndDownloadFileS3(image, shouldCache));
      })
    );
  }

  await Promise.all(requests);

  return res;
};

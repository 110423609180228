import { ApiResponse } from "interfaces/models/api";
import { axiosECS } from "services/baseAxios";

export const countTableItems = async <T>(args: {
  path: string;
  params: { [key: string]: any } & { limit: number } & T;
}): Promise<ApiResponse<{ totalItem: number; totalPage: number }>> => {
  const { data } = await axiosECS.get(`${args.path}/count-items`, {
    params: args.params,
  });

  return { data };
};

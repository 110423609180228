import { ApiResponse } from "interfaces/models/api";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq } from "utils/common";

const path = "/v2/project-bim-file";

export const getProjectList = async (): Promise<
  ApiResponse<DataProjectModel[]>
> => {
  return axiosECS.get(path);
};

export const getProject = async (
  projectId: string,
  shouldCache = false
): Promise<ApiResponse<DataProjectModel>> => {
  return axiosECS.get(`${path}/${projectId}`, {
    params: {
      shouldCache,
    },
  });
};

export const deleteProjectList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids } });
};

export const createProject = async (
  project: Partial<DataProjectModel>
): Promise<ApiResponse<DataProjectModel>> => {
  return axiosECS.post(path, validateBodyReq(project));
};

export const updateProject = async (
  project: Partial<DataProjectModel> & { id: string }
): Promise<ApiResponse<DataProjectModel>> => {
  return axiosECS.patch(path, validateBodyReq(project));
};

import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Progress,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { projectBimFileApi } from "apiClient/v2";
import { message } from "components/base";
import {
  ClearDatabaseCachedIcon,
  NetworkStatusIcon,
  SyncDataForOfflineIcon,
} from "components/icon";
import ForgeHeaderItem from "components/Layout/ForgeHeaderItem";
import ProfileMenu from "components/Layout/ProfileMenu";
import { SvgIcon } from "components/SvgIcon";
import SystemModeSwitch from "components/ui/SystemModeSwitch";
import { CACHED_PROJECT_INFO_KEY } from "constants/cache";
import { buttonHeaderItemStyle } from "constants/header";
import useCheckProjectCached, {
  iCachedProject,
} from "hooks/useCheckProjectCached";
import useGetCurrentPath from "hooks/useGetCurrentPath";
import { DataProjectModel } from "interfaces/models/dataProjectModel";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { setIsShowOfflineMsg } from "redux/appSlice";
import { setIsCreateProject } from "redux/bimSlice";
import { resetWidgetsMode } from "redux/forgeViewerSlice";
import { setDataProjectDetail } from "redux/projectSlice";
import store, { RootState } from "redux/store";
import { routePath } from "routes/path";
import { getBimFileInfo } from "utils/bim";
import { getDatabaseSize } from "utils/indexedDb";
import { logError } from "utils/logs";
import { getHumanReadableSize, getLocalStorage } from "utils/storage";
import HomeHeaderSetting from "./HomeHeaderSetting";

interface Props extends BoxProps {
  bg?: string;
  projectId?: string;
}

const Header = ({ bg, ...rest }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobileWithWidth] = useMediaQuery("(max-width: 667px)");
  const [isMobileWithHeight] = useMediaQuery("(max-height: 476px)");
  const [isWidthLessThan800] = useMediaQuery("(max-width: 800px)");
  const [isWidthLargeThan800] = useMediaQuery("(min-width: 800px)");
  const [isWidthLessThan1024] = useMediaQuery("(max-width: 1024px)");

  const {
    isOpen: isOpenMenuMobile,
    onOpen: onOpenMenuMobile,
    onClose: onCloseMenuMobile,
  } = useDisclosure();
  const [currentProject, setCurrentProject] = useState<DataProjectModel>();
  const [offlineEditedDataSize, setOfflineEditedDataSize] = useState<number>(0);
  const {
    isOnline,
    isSyncOfflineData,
    isServiceWorkerReady,
    actualNetworkStatus,
    timeBeginOffline,
    syncDataOption,
  } = useSelector((state: RootState) => state.app);
  const { cachingProjectBimFileId, dataProjectDetail, lastCheckCacheProject } =
    useSelector((state: RootState) => state.project);
  const { levels, levelSelected, widgetsMode, isLoadedViewerModelData } =
    useSelector((state: RootState) => state.forgeViewer);

  const { currentUser } = useSelector((state: RootState) => state.user);

  const timeoutRef = useRef<any>();

  const handleUpdateCacheDataSize = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      getDatabaseSize().then((value) => {
        setOfflineEditedDataSize(value);
      });
    }, 200);
  }, []);

  useEffect(() => {
    if (isOnline) {
      return;
    }
    const unSubscribe = store.subscribe(() => {
      handleUpdateCacheDataSize();
    });
    handleUpdateCacheDataSize();

    return () => {
      unSubscribe();
    };
  }, [handleUpdateCacheDataSize, isOnline]);

  const syncDataTime = useMemo(() => {
    return (
      syncDataOption?.mapSyncDataTimeByProject?.[dataProjectDetail?.id || ""] ||
      null
    );
  }, [syncDataOption, dataProjectDetail?.id]);

  const levelSelectedLabel = useMemo(
    () =>
      levelSelected.guid
        ? levels.find((c) => c.guid === levelSelected.guid)?.label
        : "",
    [levels, levelSelected.guid]
  );

  const isMobile = useMemo(
    () => isMobileWithWidth || isMobileWithHeight || isWidthLessThan800,
    [isMobileWithWidth, isMobileWithHeight, isWidthLessThan800]
  );

  const { pathPattern, params } = useGetCurrentPath();

  const isShowProfileMenu = useMemo(() => {
    return !!currentUser;
  }, [currentUser]);

  const isShowHomeIcon = useMemo(() => {
    return [routePath.Profile]?.includes(pathPattern);
  }, [pathPattern]);

  const isWidthFrom800To1024 = useMemo(() => {
    return isWidthLargeThan800 && isWidthLessThan1024;
  }, [isWidthLargeThan800, isWidthLessThan1024]);

  const isLargeScreenForgeViewer = useMemo(() => {
    return isWidthFrom800To1024 && routePath.ForgeViewer === pathPattern;
  }, [isWidthFrom800To1024, pathPattern]);

  const isShowTitle = useMemo(() => {
    return (
      !isShowHomeIcon &&
      ![routePath.UserDashboard, routePath.ProjectDashboard]?.includes(
        pathPattern
      )
    );
  }, [pathPattern, isShowHomeIcon]);

  const isShowForgeItem = useMemo(() => {
    return [
      routePath.ForgeViewer,
      routePath.DocumentTaskList,
      routePath.DocumentLevel,
      routePath.DocumentTemplate,
      routePath.DocumentTemplateList,
      routePath.DocumentTemplateEdit,
      routePath.DocumentTemplateView,
      routePath.ListTaskType,
    ]?.includes(pathPattern);
  }, [pathPattern]);

  const isShowForgeItemNew = useMemo(() => {
    return [routePath.ForgeViewer]?.includes(pathPattern);
  }, [pathPattern]);

  const isShowLogo = useMemo(() => {
    return ![routePath.Profile]?.includes(pathPattern);
  }, [pathPattern]);

  const isShowSetting = useMemo(() => {
    return (
      currentUser &&
      ![routePath.ProjectDashboard, routePath.UserDashboard]?.includes(
        pathPattern
      )
    );
  }, [pathPattern, currentUser]);

  // const isShowTabletMenu = useMemo(() => {
  //   return (
  //     !!currentUser &&
  //     width < MAX_SIZE_IPAD &&
  //     ![routePath.UserDashboard, routePath.ForgeViewer]?.includes(pathPattern)
  //   );
  // }, [currentUser, width, pathPattern]);

  // const isShowProjectDashboardButton = useMemo(() => {
  //   return (
  //     [routePath.DocumentLevel]?.includes(pathPattern) && !isShowTabletMenu
  //   );
  // }, [isShowTabletMenu, pathPattern]);

  const isShowDashBoardTitle = useMemo(() => {
    return [routePath.ProjectDashboard, routePath.UserDashboard].includes(
      pathPattern as any
    );
  }, [pathPattern]);

  const isShowClearDatabaseCached = useMemo(
    () => [routePath.Home]?.includes(pathPattern),
    [pathPattern]
  );

  const isShowSyncDataForOfflineIcon = useMemo(
    () => [routePath.ForgeViewer]?.includes(pathPattern),
    [pathPattern]
  );

  const renderHeaderTilte = () => {
    switch (pathPattern) {
      case routePath.ProjectOverview: {
        return (
          <HStack spacing="0.8rem">
            <Text whiteSpace="nowrap">プロジェクト</Text>
            {/* <SvgIcon src="/img/icon-navigation-chevron_right.svg" />
            <Text whiteSpace="nowrap">{projectDetail?.name || ""}</Text> */}
          </HStack>
        );
      }

      case routePath.Profile: {
        return <Text whiteSpace="nowrap">プロファイル</Text>;
      }

      default: {
        return (
          <>
            <Text whiteSpace="nowrap">プロジェクト選択</Text>
            {dataProjectDetail?.id && (
              <>
                <SvgIcon src="/img/icon-navigation-chevron_right.svg" />
                <Text whiteSpace="nowrap">BIM360データ選択</Text>
              </>
            )}
          </>
        );
      }
    }
  };

  const handleCloseUserDashboardModal = useCallback(() => {
    dispatch(resetWidgetsMode());
  }, [dispatch]);

  const navigateToHome = useCallback(() => {
    navigate(routePath.Home);
    dispatch(setDataProjectDetail({} as DataProjectModel));
    dispatch(setIsCreateProject(false));
    widgetsMode && dispatch(resetWidgetsMode());
  }, [dispatch, navigate, widgetsMode]);

  // const navigateToUserDashboard = () => {
  //   dispatch(setWidgetsMode(WidgetsMode.USER_DASHBOARD));
  //   navigate(routePath.UserDashboard);
  // };

  // const navigateToProjectDashboard = useCallback(() => {
  //   dispatch(setWidgetsMode(WidgetsMode.PROJECT_DASHBOARD));
  //   navigate(
  //     generatePath(routePath.ProjectDashboard, {
  //       projectId: params?.projectId,
  //     })
  //   );
  // }, [dispatch, navigate, params]);

  const navigateToForgeViewPage = async (state?: Object) => {
    if (!currentUser?.id) {
      return;
    }

    try {
      const { data: dataProjects } = await projectBimFileApi.getProjectList();
      const currentDataProject = dataProjects.find(
        (project) => project.id === params?.projectId
      );
      const urn = currentDataProject?.defaultBimPathId?.split("/").pop();
      const { bimFileId, version } = getBimFileInfo(urn || "");

      navigate(
        generatePath(routePath.ForgeViewer, {
          projectId: params?.projectId,
          bimFileId,
          version,
        }),
        {
          state,
        }
      );
    } catch (err) {
      logError(err);
    }
  };

  const getCurrentProject = useCallback(async () => {
    if (!currentUser?.id || !params?.projectId) {
      return;
    }
    const { data: dataProjects } = await projectBimFileApi.getProjectList();
    const currentDataProject = dataProjects.find(
      (project) => project.id === params?.projectId
    );

    setCurrentProject(currentDataProject);
  }, [currentUser?.id, params?.projectId]);

  useEffect(() => {
    getCurrentProject();
  }, [currentUser, getCurrentProject, pathPattern]);

  const uploadOfflineDataStatus = useMemo(() => {
    if (!isOnline) {
      return <Text>{getHumanReadableSize(offlineEditedDataSize)}</Text>;
    }

    if (isSyncOfflineData && !!offlineEditedDataSize) {
      return (
        <Flex flexDir="column" pt="5px">
          <Text userSelect="none">
            {getHumanReadableSize(offlineEditedDataSize)}
          </Text>
          <Progress
            size="sm"
            w="100%"
            bgColor={"var(--chakra-colors-gray-300)"}
            colorScheme={"completed"}
            hasStripe={true}
            isAnimated={true}
            isIndeterminate
            border="5px"
          />
        </Flex>
      );
    }

    return <></>;
  }, [isOnline, isSyncOfflineData, offlineEditedDataSize]);

  const IconHome = (props?: React.HTMLAttributes<HTMLDivElement>) => (
    <Box
      width="28px"
      height="28px"
      flexShrink={0}
      background="#215283"
      display="flex"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
      {...props}
    >
      <SvgIcon
        src="/img/icon-home.svg"
        width="18px"
        height="12px"
        flexShrink={0}
      />
    </Box>
  );

  const TitleHeader = () => (
    <Text
      fontSize="2.2rem"
      color="#215283"
      fontWeight="500"
      lineHeight="2.2rem"
      letterSpacing="1px"
      cursor="pointer"
      onClick={navigateToHome}
    >
      品質管理
    </Text>
  );

  const gridTemplateColumnsNeptuneHeader = useMemo(() => {
    if (isMobile) {
      return "1fr 1fr 1fr";
    }

    return "auto auto";
  }, [isMobile]);

  const cachedProjectsInfo = useMemo(() => {
    const cachedProjects: {
      [key: string]: iCachedProject;
    } = getLocalStorage(CACHED_PROJECT_INFO_KEY, {});

    return cachedProjects;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastCheckCacheProject]);

  const { progress, isLoading, stopCacheProject, handleBeforeSyncProjectData } =
    useCheckProjectCached({
      project: dataProjectDetail,
      cachingProjectBimFileId,
      isServiceWorkerReady,
      cachedInfo: cachedProjectsInfo?.[dataProjectDetail?.id],
      isOnline,
    });

  useEffect(() => {
    if (
      !isOnline &&
      isShowForgeItemNew &&
      isLoading !== undefined &&
      progress < 100
    ) {
      message.warning([
        "インターネット接続が切れました。",
        "オフライン環境で利用したい場合には、オフラインモードをご活用ください。（オフラインモードを利用する際には、事前にプロジェクトデータのダウンロードが必要です）",
      ]);
      navigate(routePath.Home);
    }
  }, [isOnline]);

  useEffect(() => {
    // Do not show offline messages when in the forge viewer screen without caching the project
    if (isShowForgeItemNew) {
      dispatch(setIsShowOfflineMsg(progress >= 100));
    }

    return () => {
      dispatch(setIsShowOfflineMsg(true));
    };
  }, [isShowForgeItemNew, progress >= 100]);

  const isCaching = useMemo(() => {
    return (
      !!dataProjectDetail?.id &&
      cachingProjectBimFileId === dataProjectDetail.id
    );
  }, [cachingProjectBimFileId, dataProjectDetail.id]);

  return (
    <React.Fragment>
      <Box
        id="neptune-header"
        zIndex="tooltip"
        display="grid"
        gap={isLargeScreenForgeViewer ? "0rem" : "0.8rem"}
        flexDirection="row"
        gridTemplateColumns={gridTemplateColumnsNeptuneHeader}
        alignItems="center"
        filter="drop-shadow(rgba(0, 0, 0, 0.23) 0px 2px 2.62px)"
        p={isLargeScreenForgeViewer ? "0px 0.8rem" : "0px 1.6rem"}
        bgColor={bg || "#fff"}
        h="var(--header-height)"
        {...rest}
        boxShadow="0px 1px 3px 1px #00000026"
      >
        <Flex
          overflow="visible"
          as="header"
          alignItems="center"
          gap="1.2rem"
          pr="0.8rem"
        >
          {isShowLogo && !isMobile && <IconHome onClick={navigateToHome} />}

          {isMobile && (
            <>
              {isShowForgeItem && (
                <Box cursor="pointer" onClick={onOpenMenuMobile}>
                  <HamburgerIcon fontSize={16} mr="1rem" />
                </Box>
              )}

              <Drawer
                isOpen={isOpenMenuMobile}
                placement="left"
                onClose={onCloseMenuMobile}
              >
                <DrawerOverlay />
                <DrawerContent maxW="36rem" pl="2rem">
                  <DrawerCloseButton />
                  <ForgeHeaderItem
                    isLargeScreenForgeViewer={isLargeScreenForgeViewer}
                    levelSelectedLabel={levelSelectedLabel}
                    isMobile={isOpenMenuMobile}
                  />
                </DrawerContent>
              </Drawer>
            </>
          )}

          {isShowTitle && !isLargeScreenForgeViewer && (
            <Box minW="10rem">
              <TitleHeader />
            </Box>
          )}

          {isShowSetting && (
            <Box
              mr={
                isShowClearDatabaseCached || isLargeScreenForgeViewer
                  ? "0px"
                  : "2.8rem"
              }
            >
              <HomeHeaderSetting />
            </Box>
          )}

          {isShowClearDatabaseCached && <ClearDatabaseCachedIcon />}

          {isShowHomeIcon && (
            <Flex
              className="title-wrapper"
              mr="auto !important"
              w={{ base: "100%", md: "auto" }}
              alignItems="center"
              minH="var(--header-height)"
              m="0px !important"
            >
              <SvgIcon
                src="/img/icon-home-outline.svg"
                width="24px"
                height="24px"
                cursor="pointer"
                mr="1rem"
                onClick={() => {
                  navigate(routePath.Home);
                  handleCloseUserDashboardModal();
                  dispatch(setDataProjectDetail({} as DataProjectModel));
                  dispatch(setIsCreateProject(false));
                }}
              />

              <Flex
                alignItems="center"
                fontSize="1.8rem"
                fontWeight="bold"
                color="#000000"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                gap="1rem"
              >
                {renderHeaderTilte()}
              </Flex>
            </Flex>
          )}

          {isShowDashBoardTitle && (
            <Flex alignItems="center" columnGap="1rem">
              <TitleHeader />

              {pathPattern === routePath.ProjectDashboard && (
                <>
                  {currentProject?.name && (
                    <SvgIcon src="/img/icon-navigation-chevron_right.svg" />
                  )}
                  <Text fontSize="16px" fontWeight="bold">
                    {currentProject?.name || ""}
                  </Text>

                  <Button
                    variant="outline"
                    color="#009BE0"
                    fontSize="14px"
                    fontWeight="500"
                    h="4rem"
                    bg="white"
                    {...buttonHeaderItemStyle}
                    onClick={navigateToForgeViewPage}
                  >
                    BIM画面
                  </Button>
                </>
              )}
            </Flex>
          )}

          {!isMobile && isShowForgeItem && (
            <React.Fragment>
              {isShowForgeItemNew && (
                <ForgeHeaderItem
                  isLargeScreenForgeViewer={isLargeScreenForgeViewer}
                  levelSelectedLabel={levelSelectedLabel}
                />
              )}
            </React.Fragment>
          )}
        </Flex>

        {isMobile && (
          <Flex
            justify="space-between"
            gap="0.8rem"
            pl="0.8rem"
            py="0.8rem"
            backgroundColor="white"
            overflow="visible"
            sx={{
              ".chakra-wrap__list": {
                alignItems: "center",
                overflow: "visible",
              },
            }}
            flex="1"
            alignItems="center"
          >
            <Stack
              justifyContent="space-between"
              alignItems="center"
              flexDir={{ base: "column", lg: "row" }}
              flexFlow="nowrap"
              w="100%"
            >
              <Flex flex="1" justifyContent="center">
                <IconHome onClick={navigateToHome} />
              </Flex>
            </Stack>
          </Flex>
        )}

        <Flex
          width="100%"
          gap={isLargeScreenForgeViewer ? "0.2rem" : "0.8rem"}
          alignItems="center"
          justifyContent="flex-end"
        >
          {uploadOfflineDataStatus}
          {isShowSyncDataForOfflineIcon && (
            <SyncDataForOfflineIcon
              isCaching={isCaching}
              progress={progress}
              isOnline={isOnline}
              cachingProjectBimFileId={cachingProjectBimFileId || ""}
              actualNetworkStatus={actualNetworkStatus}
              syncDataTime={syncDataTime}
              projectBimfileId={dataProjectDetail.id}
              stopCacheProject={stopCacheProject}
              syncNewProjectData={handleBeforeSyncProjectData}
            />
          )}

          {/* <PermissionToggle feature="canAccessProjectDashboard">

            {isShowProjectDashboardButton && (
              <Button
                onClick={navigateToProjectDashboard}
                h="4.4rem"
                variant="outline"
                _hover={{ bg: "#F0F9FF" }}
                {...buttonHeaderItemStyle}
                isDisabled={!isOnline}
              >
                プロジェクトのダッシュボード
              </Button>
            )}
          </PermissionToggle> */}

          {/* <PermissionToggle feature="canAccessUserDashboard">
            {isShowUserDashBoardButton && (
              <Button
                onClick={navigateToUserDashboard}
                h="4.4rem"
                variant="outline"
                _hover={{ bg: "#F0F9FF" }}
                {...buttonHeaderItemStyle}
                isDisabled={!isOnline}
              >
                ユーザーのダッシュボード
              </Button>
            )}
          </PermissionToggle> */}

          {/* {isShowTabletMenu && (
            <TabletMenu projectId={params?.projectId || ""} />

          )} */}

          <NetworkStatusIcon
            isOnline={isOnline}
            isCacheFinished={progress >= 100}
            isLoadingViewer={isShowForgeItemNew && !isLoadedViewerModelData}
            cachingProjectBimFileId={cachingProjectBimFileId || ""}
            actualNetworkStatus={actualNetworkStatus}
            timeBeginOffline={timeBeginOffline}
            isPageForgeViewer={isShowForgeItemNew}
          />
          {isShowProfileMenu && (
            <ProfileMenu
              width={isLargeScreenForgeViewer ? "2.4rem" : "3.6rem"}
              marginRight={isLargeScreenForgeViewer ? "1.2rem" : "0px"}
              height={isLargeScreenForgeViewer ? "2.4rem" : "3.6rem"}
            />
          )}
        </Flex>
      </Box>
      {isShowForgeItem && isShowForgeItemNew && (
        <Box
          id="neptune-switch-mode-header"
          alignItems="center"
          p="0"
          bgColor={"#FAFAFA"}
          h="var(--sub-header-height)"
          borderBottom="2px solid #D4D4D4"
        >
          <SystemModeSwitch
            isForgePage={isShowForgeItem}
            isMobile={isMobile}
            projectId={params?.projectId}
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export default Header;

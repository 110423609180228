import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuItem,
  MenuList,
  Spinner,
} from "@chakra-ui/react";
import { withPresignedUrl } from "components/HOC/presignedUrl";
import { defaultAvatarPath } from "constants/file";
import { useResize } from "hooks/useResize";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetWidgetsMode } from "redux/forgeViewerSlice";
import { RootState } from "redux/store";
import { routePath } from "routes/path";
import { logout } from "utils/user";

const MenuButtonPresigned = withPresignedUrl(MenuButton);

interface Props extends MenuButtonProps {}

function ProfileMenu({ ...rest }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isOnline } = useSelector((state: RootState) => state.app);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { widgetsMode } = useSelector((state: RootState) => state.forgeViewer);
  const [loading, setLoading] = useState<boolean>(false);
  const { height = 0 } = useResize();

  const onLogout = () => {
    widgetsMode && dispatch(resetWidgetsMode());
    setLoading(true);
    logout();
    setLoading(false);
  };

  const openProfilePage = () => {
    widgetsMode && dispatch(resetWidgetsMode());

    return navigate(routePath.Profile);
  };

  return (
    <Box flexShrink="0" bg="white">
      <Menu autoSelect={false}>
        <MenuButtonPresigned
          w="3.6rem"
          h="3.6rem"
          cursor="pointer"
          as={Avatar}
          src={currentUser?.avatar || defaultAvatarPath}
          className="profile-menu"
          {...rest}
        />
        <MenuList ml="1rem" minW="17.6rem" p="0">
          <MenuItem
            onClick={openProfilePage}
            p=".5rem 1.5rem"
            lineHeight="2.4rem"
            color="#171717"
            borderBottom="1px solid var(--primary-border-color)"
            _hover={{ bgColor: "#F0F9FF !important" }}
          >
            ユーザー
          </MenuItem>
          <MenuItem
            isDisabled={!isOnline}
            onClick={onLogout}
            color="#171717"
            p=".5rem 1.5rem"
            _hover={{ bgColor: "#F0F9FF !important" }}
          >
            ログアウト
          </MenuItem>
        </MenuList>
      </Menu>

      <Flex
        width="100%"
        height={height}
        position="absolute"
        opacity="0.4"
        zIndex="10"
        top="calc(var(--header-height) * -1)"
        background="white"
        justifyContent="center"
        alignItems="center"
        display={loading ? "flex" : "none"}
      >
        <Spinner
          emptyColor="gray.200"
          thickness="3px"
          color="blue.500"
          size="xl"
        />
      </Flex>
    </Box>
  );
}

export default ProfileMenu;

import Layout from "components/Layout";
import LayoutTabs from "components/LayoutTabs";
import Loading from "components/Loading";
import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { routePath } from "./path";
import PrivateRoute from "./PrivateRoute";
import ShortenRoute from "./ShortenRoute";

// const SignUpPage = React.lazy(() => import("pages/signup"));
const ProfilePage = import("pages/profile");
const ProjectOverviewPage = import("pages/project-overview");
// const ChangePasswordPage = import("pages/change-password");
const HomePage = import("pages/projects-page");
// const HomePage = import("pages/home-page/home");
const ForgeViewerPage = import("pages/forge-viewer");
const DocumentFloorPage = import("pages/document/document-level-page");
const DocumentTemplatePage = import("pages/document/template-page");
const UserDashboardPage = import("pages/dashboard/user-dashboard");
const ProjectDashboard = import("pages/dashboard/project-dashboard");
const DocumentTemplateList = import("pages/document/document-template-list");
const DocumentTaskList = import("pages/task/document-task-list");
const ListTaskType = import("pages/list-task-type");
const AccessErrorPage = import("pages/AccessErrorPage");
const NotFoundPage = import("pages/NotFoundPage");
const SchedulesPage = import("pages/schedules");

// blackboard tempalte
const BlackboardTemplateList = import(
  "pages/document/blackboard-template/list"
);
const BlackboardTemplate = import("pages/document/blackboard-template");

// tasksheet template
const TaskSheetTemplateList = import("pages/document/tasksheet-template/list");
const TaskSheetTemplate = import("pages/document/tasksheet-template");

const SettingFamily = import("pages/setting-family");

interface RouteI {
  path: string;
  Component: React.ElementType;
  isCheckAuth?: Boolean;
  props?: Object;
  privateRouteProps?: Object;
  children?: RouteI[];
}

const routes: RouteI[] = [
  {
    path: routePath.DocumentTaskList,
    Component: lazy(() => DocumentTaskList),
    isCheckAuth: true,
  },
  {
    path: routePath.Shorten,
    Component: ShortenRoute,
    isCheckAuth: false,
    privateRouteProps: {
      callbackUrl: window.location.pathname + window.location.search,
    },
  },
  {
    path: routePath.Profile,
    Component: lazy(() => ProfilePage),
    isCheckAuth: true,
  },
  // {
  //   path: routePath.ChangePassword,
  //   Component: lazy(() => ChangePasswordPage),
  //   isCheckAuth: true,
  // },
  {
    path: routePath.Home,
    Component: lazy(() => HomePage),
    isCheckAuth: true,
  },
  {
    path: routePath.UserDashboard,
    Component: lazy(() => UserDashboardPage),
    isCheckAuth: true,
  },
  {
    path: routePath.ProjectDashboard,
    Component: lazy(() => ProjectDashboard),
    isCheckAuth: true,
  },
  {
    path: routePath.ForgeViewer,
    Component: lazy(() => ForgeViewerPage),
    isCheckAuth: true,
  },
  {
    path: routePath.DocumentLevel,
    Component: lazy(() => DocumentFloorPage),
    isCheckAuth: true,
  },
  {
    path: routePath.DocumentTemplate,
    Component: lazy(() => DocumentTemplatePage),
    isCheckAuth: true,
  },
  {
    path: routePath.DocumentTemplateEdit,
    Component: lazy(() => DocumentTemplatePage),
    isCheckAuth: true,
  },
  {
    path: routePath.DocumentTemplateView,
    Component: lazy(() => DocumentTemplatePage),
    isCheckAuth: true,
    props: {
      isOnlyView: true,
    },
  },
  {
    path: routePath.DocumentTemplateList,
    Component: lazy(() => DocumentTemplateList),
    isCheckAuth: true,
  },

  // blackboard template
  {
    path: routePath.blackboardTemplateList,
    Component: lazy(() => BlackboardTemplateList),
    isCheckAuth: true,
  },
  {
    path: routePath.blackboardTemplateEdit,
    Component: lazy(() => BlackboardTemplate),
    isCheckAuth: true,
    props: {
      isBlackboardTemplate: true,
    },
  },
  {
    path: routePath.blackboardTemplate,
    Component: lazy(() => BlackboardTemplate),
    isCheckAuth: true,
    props: {
      isBlackboardTemplate: true,
    },
  },

  // tasksheet template
  {
    path: routePath.TaskSheetTemplateList,
    Component: lazy(() => TaskSheetTemplateList),
    isCheckAuth: true,
  },
  {
    path: routePath.TaskSheetTemplateEdit,
    Component: lazy(() => TaskSheetTemplate),
    isCheckAuth: true,
  },
  {
    path: routePath.TaskSheetTemplate,
    Component: lazy(() => TaskSheetTemplate),
    isCheckAuth: true,
  },
  {
    path: routePath.TaskSheetTemplateView,
    Component: lazy(() => TaskSheetTemplate),
    isCheckAuth: true,
    props: {
      isOnlyView: true,
    },
  },
  {
    path: routePath.SettingFamily,
    Component: lazy(() => SettingFamily),
    isCheckAuth: true,
  },
  {
    path: routePath.ProjectOverview,
    Component: lazy(() => ProjectOverviewPage),
    isCheckAuth: true,
  },
  {
    path: routePath.Home,
    Component: LayoutTabs,
    isCheckAuth: true,
  },
  {
    path: "/",
    Component: Navigate,
    isCheckAuth: true,
    props: {
      to: routePath.Home,
      replace: true,
    },
  },
  {
    path: routePath.ListTaskType,
    Component: lazy(() => ListTaskType),
    isCheckAuth: true,
  },
  {
    path: routePath.Schedules,
    Component: lazy(() => SchedulesPage),
    isCheckAuth: true,
  },
  {
    path: routePath.AccessError,
    Component: lazy(() => AccessErrorPage),
    isCheckAuth: false,
  },
  {
    path: routePath.NotFound,
    Component: lazy(() => NotFoundPage),
    isCheckAuth: false,
  },
  {
    path: "*",
    Component: lazy(() => NotFoundPage),
    isCheckAuth: false,
  },
];

function AppRoute() {
  return (
    <Layout>
      <Routes>
        {routes?.map(
          (
            {
              path,
              Component,
              isCheckAuth,
              props,
              privateRouteProps,
              children,
            },
            index
          ) => (
            <Route
              key={index}
              path={path}
              element={
                isCheckAuth ? (
                  <PrivateRoute {...privateRouteProps}>
                    <Suspense fallback={<Loading />}>
                      <Component {...props} />
                    </Suspense>
                  </PrivateRoute>
                ) : (
                  <Suspense fallback={<Loading />}>
                    <Component {...props} />
                  </Suspense>
                )
              }
            >
              {children?.map(
                (
                  {
                    path: childPath,
                    Component: ChildComponent,
                    props: childProps,
                  },
                  childIndex
                ) => (
                  <Route
                    key={childIndex}
                    path={childPath}
                    element={
                      <Suspense fallback={<Loading />}>
                        <ChildComponent {...childProps} />
                      </Suspense>
                    }
                  />
                )
              )}
            </Route>
          )
        )}
      </Routes>
    </Layout>
  );
}

export default AppRoute;

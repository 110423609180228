import { DocumentCategoryKey } from "constants/enum";
import { ApiResponse } from "interfaces/models/api";
import { DocumentCategory } from "interfaces/models/documentCategory";
import { isPhotoLedgerTemplate } from "models/documentCategory";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";
import {
  blackboardApi,
  documentApi,
  documentItemLogApi,
  documentKeyNoteApi,
  documentTemplateApi,
} from ".";

const path = "/v2/document-categories";
interface GetCategoryReq {
  bimFileId?: string;
  userAssigned?: string;
  documentCategoryId?: string;
  signal?: AbortSignal;
  shouldCache?: boolean;
  level?: string;
}

const getCategoryList = async ({
  bimFileId,
  userAssigned,
  level,
  documentCategoryId,
  signal,
  shouldCache,
}: GetCategoryReq): Promise<ApiResponse<DocumentCategory[]>> => {
  return await axiosECS.get(path, {
    params: {
      bimFileId: encodeURIComponent(bimFileId || ""),
      level,
      userAssigned,
      documentCategoryId,
      shouldCache,
    },
    signal,
  });
};

export const createCategory = async ({
  category,
  isGenerateDocumentItems = true,
}: {
  category: Partial<DocumentCategory>;
  isGenerateDocumentItems?: boolean;
}): Promise<ApiResponse<DocumentCategory>> => {
  const cloneDocumentCategory = structuredClone(category);
  delete (cloneDocumentCategory as any)?.documentItems;
  delete (cloneDocumentCategory as any)?.documentSubCategories;
  delete (cloneDocumentCategory as any)?.childTemplates;

  return axiosECS.post(
    path,
    validateBodyReq({ ...cloneDocumentCategory, isGenerateDocumentItems })
  );
};

export const updateCategory = async (
  category: Required<Pick<DocumentCategory, DocumentCategoryKey.ID>> &
    Partial<DocumentCategory> & { isBlocked?: boolean }
): Promise<ApiResponse<DocumentCategory>> => {
  category.updatedAt = category.updatedAt || new Date();

  return axiosECS.patch(path, validateBodyReq(category));
};

export const deleteCategoryList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, {
    data: { ids: validateListString(ids) },
  });
};

export const handleGetCategoryList = async ({
  bimFileId,
  shouldCache,
  userAssigned,
  signal,
  level,
}: GetCategoryReq) => {
  const { data: res } = await getCategoryList({
    bimFileId,
    level,
    userAssigned,
    signal,
    shouldCache,
  });

  const categories = res.filter(
    (doc: any) => !doc.deletedAt
  ) as DocumentCategory[];

  if (shouldCache) {
    categories?.forEach(async (category) => {
      await documentTemplateApi
        .getTemplate(category.templateId, shouldCache)
        .catch(() => undefined);

      await documentApi
        .handleGetDocumentList({
          documentCategoryIds: [category.id],
          shouldCache,
        })
        .catch(() => ({ data: [] }));

      await documentItemLogApi
        .handleGetItemLogList({
          categoryId: category.id,
          shouldCache,
        })
        .catch(() => ({ data: [] }));

      if (isPhotoLedgerTemplate(category?.documentType)) {
        await blackboardApi
          .getBlackboardList({ documentCategoryId: category.id, shouldCache })
          .catch(() => {
            return;
          });

        await documentKeyNoteApi
          .handleGetKeynoteList(category.id, shouldCache)
          .catch(() => {
            return;
          });
      }
    });
  }

  return categories;
};

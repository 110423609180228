import { ApiResponse } from "interfaces/models/api";
import { CustomComponentReq } from "interfaces/models/component";
import { axiosECS } from "services/baseAxios";
import { validateBodyReq, validateListString } from "utils/common";

const path = "/v2/document-components";

export const getComponentList = async (): Promise<
  ApiResponse<CustomComponentReq[]>
> => {
  return axiosECS.get(path);
};

export const createComponent = async (
  component: Partial<CustomComponentReq>
): Promise<ApiResponse<CustomComponentReq>> => {
  return axiosECS.post(path, validateBodyReq(component));
};

export const updateComponent = async (
  component: Partial<CustomComponentReq>
): Promise<ApiResponse<CustomComponentReq>> => {
  return axiosECS.patch(path, validateBodyReq(component));
};

export const deleteComponentList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids:validateListString(ids) } });
};

import { ApiResponse } from "interfaces/models/api";
import { axiosECS } from "services/baseAxios";
import { AxiosRequestConfig } from "axios";

interface S3PresignedUrlReq {
  filePath: string;
  fileName: string;
  s3Method: string;
}

export interface PresignedUrlMultipartUploadData {
  presignedUrl: string;
  PartNumber: number;
}

const path = "/v2/s3";

export const getS3FileSize = async (data: {
  fileName: string;
  filePath: string;
}): Promise<ApiResponse<{ contentLength: number }>> => {
  return axiosECS.post(`${path}/get-s3-file-size`, data);
};

export const initialMultipartUpload = async (data: {
  fileName: string;
  filePath: string;
}): Promise<ApiResponse<{ fileId: string; fileKey: string }>> => {
  return axiosECS.post(`${path}/s3-initial-multipart-upload`, data);
};

export const presignedUrlMultipartUpload = async (data: {
  fileId: string;
  fileKey: string;
  numberOfparts: number;
}): Promise<ApiResponse<PresignedUrlMultipartUploadData[]>> => {
  return axiosECS.post(`${path}/s3-presigned-url-multipart-upload`, data);
};

export const completeMultipartUpload = async (data: {
  fileId: string;
  fileKey: string;
  Parts: { ETag: string; PartNumber: number }[];
}) => {
  return axiosECS.post(`${path}/s3-complete-multipart-upload`, data);
};

export const presignedUrl = async (
  data: S3PresignedUrlReq,
  config?: AxiosRequestConfig
): Promise<ApiResponse<string>> => {
  return axiosECS.post(`${path}/s3-presigned-url`, data, config);
};

export const uploadToS3 = async ({
  presignedUrl,
  file,
  isIgnoreShowMessageError = false,
}: {
  presignedUrl: string;
  file: Blob | File;
  isIgnoreShowMessageError?: boolean;
}) => {
  return axiosECS.put("", file, {
    baseURL: presignedUrl,
    headers: {
      "Content-Type": file.type,
      isIgnoreShowMessageError,
    },
    withCredentials: false,
  });
};

export const deleteFiles = async (
  items: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(`${path}`, { data: { items } });
};

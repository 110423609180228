import { ApiResponse, Pagination } from "interfaces/models/api";
import { TaskType } from "interfaces/models/taskType";
import { axiosECS } from "services/baseAxios";
import { getNetworkStatus, validateBodyReq } from "utils/common";
import { cloneDeep } from "lodash";
import { countTableItems } from "./commonApi";
import { presignedAndDownloadFileS3 } from "utils/file";

const path = "/v2/task-types";
const DEFAULT_LIMIT = 1000;

interface GetTaskTypesReq extends Pagination {
  title?: string;
  deleted?: boolean;
  exact?: boolean;
  shouldCache?: boolean;
}

const getTaskTypesList = async (
  params: GetTaskTypesReq
): Promise<ApiResponse<TaskType[]>> => {
  const { limit } = params;

  return axiosECS.get(path, {
    params: { ...params, limit: limit || DEFAULT_LIMIT },
  });
};

export const getTaskType = async (
  id: string
): Promise<ApiResponse<TaskType>> => {
  const result = await axiosECS.get(`${path}/${id}`, {
    params: {
      // not use cache's data in service worker
      useCache: false,
    },
  });

  // case service worker return response type array
  if (Array.isArray(result.data)) {
    result.data = result.data?.[0];
  }

  return result;
};

export const createTaskType = async (
  taskType: Partial<TaskType>
): Promise<ApiResponse<TaskType>> => {
  return axiosECS.post(path, validateBodyReq(taskType));
};

export const updateTaskType = async (
  taskType: TaskType
): Promise<ApiResponse<TaskType>> => {
  const nullFields = [
    "goodImageUrl",
    "badImageUrl",
    "goodDescription",
    "badDescription",
  ];
  const bodyClone = cloneDeep(taskType) as { [key: string]: any };
  nullFields.forEach((e) => (bodyClone[e] = bodyClone[e] || null));
  for (const key in bodyClone) {
    if (bodyClone[key] === "") {
      bodyClone[key] = bodyClone[key] || null;
    }
  }

  return axiosECS.patch(path, bodyClone);
};

export const deleteTaskTypeList = async (
  ids: string[]
): Promise<ApiResponse<string[]>> => {
  return axiosECS.delete(path, { data: { ids } });
};

export const getTaskTypeByTitle = async (title: string) => {
  return getTaskTypesList({ title, exact: true });
};

export const handleGetTaskTypes = async (params: GetTaskTypesReq) => {
  params.paging = params.paging || "offset";
  const taskTypes: TaskType[] =
    params.paging === "cursor"
      ? await getTaskTypeWithCursor(params)
      : await getTaskTypeWithOffset(params);

  if (params.shouldCache) {
    await Promise.all(
      taskTypes.map(async (type) => {
        if (type.badImageUrl) {
          await presignedAndDownloadFileS3(
            type.badImageUrl,
            params.shouldCache
          );
        }
        if (type.goodImageUrl) {
          await presignedAndDownloadFileS3(
            type.goodImageUrl,
            params.shouldCache
          );
        }
      })
    );
  }

  return taskTypes;
};

export const getTaskTypeWithCursor = async (params: GetTaskTypesReq) => {
  const taskTypes: TaskType[] = [];
  let cursor: string | undefined;
  do {
    const { data, pagination } = await getTaskTypesList({
      deleted: params.deleted,
      cursor,
      paging: "cursor",
      shouldCache: params.shouldCache,
    });
    cursor = pagination?.cursor;
    if (data.length) {
      taskTypes.push.apply(taskTypes, data);
    }
  } while (cursor);

  return taskTypes;
};

const getTaskTypeWithOffset = async (params: GetTaskTypesReq) => {
  const taskTypes: TaskType[] = [];
  const {
    data: { totalItem, totalPage },
  } = await countTableItems<GetTaskTypesReq>({
    path,
    params: {
      limit: params.limit ? +params.limit : DEFAULT_LIMIT,
      deleted: params.deleted,
      shouldCache: params.shouldCache,
    },
  });

  const isOnline = getNetworkStatus();

  const promise = async (page: number) => {
    const { data } = await getTaskTypesList({
      ...params,
      page,
      paging: "offset",
      total: totalPage,
    });
    if (data.length) taskTypes.push.apply(taskTypes, data);
  };

  if (totalItem && totalPage) {
    if (!isOnline) {
      const pages = Array.from({ length: totalPage }, (_, i) => i + 1);
      for await (const page of pages) {
        await promise(page);
      }
    } else {
      await Promise.all(
        Array.from({ length: totalPage }, (_, i) => i + 1).map(async (page) =>
          promise(page)
        )
      );
    }
  }

  return taskTypes;
};
